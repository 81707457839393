import React from 'react'

export const Section = (props) => {
    return (
        <div className="section">
            {props.section !== 'null' && <h5>
                {props.section}
            </h5>}
            {props.children}
        </div>
    )
}
