import { get } from "axios";
import { BASE_URL } from "./apiUtils";
import i18next from "i18next";


export function getRecipes() {
    return get(`${BASE_URL}/api/recipes/Public?locale=${i18next.language.substring(0, 2)}`);
}

export function getRecipeById(id) {
    return get(`${BASE_URL}/api/recipe/${id}`);
}