import React from 'react';
import { withTranslation } from 'react-i18next';
import './Recipe.scss';

const RecipeHeader = ({ recipe, t }) => {

    return (
        <>
            <div className="column header">
                <div className="container">
                    <div className="row">
                        <div className="column">
                            <h3>{recipe ? recipe.name : 'Loading'}</h3>
                        </div>
                    </div>
                    {recipe && <>
                        <div className="row">
                            <div className="column">
                                <p>{recipe.description}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                {recipe.preparation_time && <p>{t("recipe.prep_time")}: {recipe.preparation_time} minutes</p>}
                                {recipe.cooking_time && <p>{t("recipe.cooking_time")}: {recipe.cooking_time} minutes</p>}
                                {recipe.cooking_temperature && <p>{t("recipe.cooking_temperature")}: {recipe.cooking_temperature}°F</p>}
                                {recipe.portion_number && <p>{t("recipe.servings")}: {recipe.portion_number}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <em>{t("recipe.by")} {recipe.user_name}</em>
                            </div>
                        </div>
                    </>
                    }
                </div>
                {recipe?.thumb_main_picture ? <img className="background-header" alt="recipe" src={recipe.main_picture} /> : ''}
            </div>
        </>
    );
}

export default withTranslation()(RecipeHeader);
