import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import en from './locale/en/translation';
import fr from './locale/fr/translation';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "en",
        debug: false,
        ns: ['translation'],
        resources: {},

        interpolation: {
            escapeValue: false
        }
    });

i18n.addResourceBundle('en', 'translation', en);
i18n.addResourceBundle('fr', 'translation', fr);