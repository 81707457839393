import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

const Navigation = ({ t }) => {
    const [lang, setLang] = useState(i18next.language.startsWith('fr') ? 'en' : 'fr');

    function changeLng(event) {
        event.preventDefault();
        const newLanguage = lang === 'fr' ? 'en' : 'fr';
        i18next.changeLanguage(lang);
        setLang(newLanguage);
    }

    return (
        <nav className="navigation">
            <section className="container">
                <Link className="navigation-title" to="/">MiamMiam</Link>
                <ul className="navigation-list float-right">
                    <li className="navigation-item">
                        <Link className="navigation-link" to="/about">{t("navigation.about")}</Link>
                    </li>
                    <li className="navigation-item">
                        <Link className="navigation-link" to="/recipes">{t("navigation.recipes")}</Link>
                    </li>
                    <li className="navigation-item">
                        <a href="." className="navigation-link" onClick={changeLng}>{lang}</a>
                    </li>
                </ul>

            </section>
        </nav>
    )
}

export default withTranslation()(Navigation);