import React from 'react'
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
const Home = ({ t }) => {
    return (
        <>
            <h3>{t("homepage.title")}</h3>
            <p>{t("homepage.p1a")}<a href={"https://" + t("navigation.miammiam_link")}>{t("navigation.miammiam_link")}</a>{t("homepage.p1b")}<Link className="navigation-link" to="/about">{t("navigation.about")}</Link></p>
            <p>{t("homepage.p2")}</p>
            <p>{t("homepage.p3a")}<Link className="navigation-link" to="/recipes">{t("navigation.public_recipes")}</Link>{t("homepage.p3b")}</p>
            <p>{t("homepage.p4")}<a href="https://gitlab.com/pkarampournis/miammiam-react">{t("navigation.gitlab_repo")}</a></p>
        </>
    );
}

export default withTranslation()(Home);