import React from 'react'
import { withTranslation } from 'react-i18next';

const About = ({ t }) => {
    return (
        <>
            <h3>{t("navigation.about")}</h3>
            <h4>MiamMiam</h4>
            <p><a href={"https://" + t("navigation.miammiam_link")}>{t("navigation.miammiam_link")}</a>{t("about.p1")}</p>
            <p>{t("about.p2")}</p>
            <p>{t("about.p3a")}<a href="https://gitlab.com/pkarampournis/miammiam-react">{t("about.on_gitlab")}</a>{t("about.p3b")}</p>
            <h4>Pierre Karampournis</h4>
            <p>{t("about.p4")}<a href="https://pierrekarampournis.website">pierrekarampournis.website</a></p>
            <p>{t("about.p5")}</p>
            <p>{t("about.p6")}</p>
            <p>{t("about.p7")}</p>
        </>
    )
};

export default withTranslation()(About);