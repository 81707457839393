import React, { useState, useEffect } from 'react';
import { getRecipes } from '../../services/recipeApi';
import RecipePreview from './RecipePreview';
import { withTranslation } from 'react-i18next';

const Recipes = ({ i18n, t }) => {
    const [recipes, setRecipes] = useState();
    const [done, setDone] = useState(false);

    useEffect(() => {
        getRecipes()
            .then(json => {
                setRecipes(json.data.data);
                setDone(true);
            });
    }, [i18n.language]);

    return (<>
        <h3>{t("recipes.title")}</h3>
        <div className="grid">
            {done ? recipes.map(recipe => <RecipePreview key={recipe.id} recipe={recipe}></RecipePreview>) : ''}
        </div>
    </>);
};

export default withTranslation()(Recipes);
