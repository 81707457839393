import React from 'react';
import { withTranslation } from 'react-i18next';
import './Recipe.scss';

const RecipeSteps = ({ steps, t }) => {


    return (
        <>
            <div className="column">
                <ol>
                    {steps.map((step) => <li key={step.id}>{step.description}</li>)}
                </ol>
            </div>

        </>
    );
}

export default withTranslation()(RecipeSteps);
