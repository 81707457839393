import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getIngredientsByRecipeId } from '../../services/ingredientApi';
import { getStepsByRecipeId } from '../../services/preparationApi';
import { getRecipeById } from '../../services/recipeApi';
import './Recipe.scss';
import RecipeHeader from './RecipeHeader';
import RecipeIngredients from './RecipeIngredients';
import RecipeSteps from './RecipeSteps';
import { Section } from './Section';
import { withTranslation } from 'react-i18next';
import { groupBySection } from '../../utils/sectionUtils';

const Recipe = ({ t }) => {
    let recipeParam = useParams();
    let [recipe, setRecipe] = useState();
    let [ingredients, setIngredients] = useState();
    let [steps, setSteps] = useState();

    useEffect(() => {
        if (recipe === undefined) {
            getRecipeById(recipeParam.recipeId)
                .then(json => {
                    let recipeRes = json.data.data[0];
                    setRecipe(recipeRes);
                    let ingredientsReq = getIngredientsByRecipeId(recipeRes.id);
                    let stepsReq = getStepsByRecipeId(recipeRes.id);

                    axios.all([ingredientsReq, stepsReq]).then(
                        axios.spread((...res) => {
                            setIngredients(groupBySection(res[0].data.data));
                            setSteps(groupBySection(res[1].data.data));
                        })
                    );
                });
        }
    });
    return (
        <>
            <div className="row">
                <RecipeHeader recipe={recipe}></RecipeHeader>
            </div>
            <div className="row">
                <div className="container">
                    {ingredients ?
                        <>
                            <h4>{t("recipe.ingredients")}</h4>
                            <div className="row">
                                {Object.keys(ingredients).map(section => (
                                    <Section section={section}>
                                        <RecipeIngredients ingredients={ingredients[section]}></RecipeIngredients>
                                    </Section>
                                ))}
                            </div>
                        </>
                        : ''}
                    {steps ?
                        <>
                            <h4>{t("recipe.preparation")}</h4>
                            <div className="row">
                                {Object.keys(steps).map(section => (
                                    <Section section={section}>
                                        <RecipeSteps steps={steps[section]}></RecipeSteps>
                                    </Section>
                                ))}
                            </div>
                        </>
                        : ''}
                </div>
            </div>
        </>
    );
}

export default withTranslation()(Recipe);
