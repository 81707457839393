import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.scss';
import Recipes from './recipeList/Recipes';
import Recipe from './recipeDetails/Recipe';
import Home from './Home';
import About from './About';
import Navigation from './Navigation';


const App = () => {

  return (

    <main className="wrapper">
      <Router>
        <Navigation></Navigation>
        <section className="wrapper">
          <div className="container">
            <Switch>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/recipes">
                <Recipes />
              </Route>
              <Route path="/recipe/:recipeId">
                <Recipe />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </section>
      </Router>
    </main>
  );
}

export default App;
