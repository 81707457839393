import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './Recipe.scss';
import { getIngredientQuantityType, getIngredientQuantity } from '../../utils/quantityUtils';


class RecipeIngredients extends Component {

    constructor(props) {
        super(props);
        this.getIngredientQuantityType = getIngredientQuantityType.bind(this);
        this.getIngredientQuantity = getIngredientQuantity.bind(this);
    }

    render() {
        return (
            <>
                <div className="column">
                    <ul>
                        {this.props.ingredients.map((ing) => <li key={ing.id}>{this.getIngredientQuantity(ing)} {this.getIngredientQuantityType(ing)} {ing.name}</li>)}
                    </ul>
                </div>
            </>
        )
    }
}


export default withTranslation()(RecipeIngredients);
