import React from 'react'
import { Link } from 'react-router-dom'

export default function RecipePreview({ recipe }) {
    return (
        <>
            <div className="grid-item">
                <Link to={`recipe/${recipe.slug}`}>
                    <div className="thumbnail">{recipe.thumb_main_picture && <img src={recipe.thumb_main_picture} alt={recipe.name}></img>}</div>
                    {recipe.name}</Link>
            </div>
        </>
    )
}
