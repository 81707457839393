const tr = {
    "homepage": {
        "title": "Welcome to MiamMiam, in React",
        "p1a": "This app is a pet project built in React to experiment with the library. This is a spin-off of my official Angular version of this recipe app (",
        "p1b": "), more details there: ",
        "p2": "My main goal with this project is to practice a full React.js ecosystem, with async HTTP calls, internationalization, user-based security and more...",
        "p3a": "If you somehow land on this page, welcome and enjoy this app! You can head to the ",
        "p3b": " or the login page.",
        "p4": "If you wish to take a look at the source code, you can go look on my "
    },
    "navigation": {
        "about": "About",
        "recipes": "Recipes",
        "public_recipes": "public recipes",
        "miammiam_link": "miammiam.app",
        "gitlab_repo": "Gitlab repo"
    },
    "recipes": {
        "title": "Recipes"
    },
    "recipe": {
        "ingredients": "Ingredients",
        "preparation": "Preparation",
        "prep_time": "Preparation Time",
        "cooking_time": "Cooking Time",
        "cooking_temperature": "Cooking Temperature",
        "servings": "Servings",
        "by": "By"
    },
    "about": {
        "p1": " is a private recipe cookbook app where users can also share vegan recipes with the world.",
        "p2": "This app is a port of the official Angular-built miammiam.app to React.js. The backend is the same NodeJs API MiamMiam uses, but this front-end app is entirely built with React.js, along with libraries such as Create-react-app, Axios and Milligram as a lite CSS framework",
        "p3a": "I decided to make this project open source (",
        "p3b": ") to improve my JS skills from others feedback and share what I am learning to others benefit.",
        "p4": "I am a software engineer, currently vice president in the role of tech lead and Scrum Master at Morgan Stanley. If you want to know more about me you can always visit my portfolio: ",
        "p5": "Passionate about technologies, I keep learning and practicing on many different topics primarily around, but not limited to, web development: React, Javascript, Angular, Machine learning, Node, Python and many others.",
        "p6": "My thirst of knowledge doesn't stop at technologies: I am an amateur studio photograph, sourdough bread maker, youtuber, guitarist and meditator.",
        "p7": "Finally and most importantly, I am a loving father of 3 and put most of my time and energy getting the most out life with my wife and kids.",
        "on_gitlab": "on Gitlab"
    },
    "qty": {
        "cup": "cup",
        "cup_plural": "cups",
        "tsp": "tsp",
        "tsp_plural": "tsp",
        "tbsp": "tbsp",
        "tbsp_plural": "tbsp"
    }
}

export default tr;