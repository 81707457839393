export function getIngredientQuantityType(ingredient) {
    if (ingredient === null) {
        return '';
    }

    switch (ingredient.quantity_type_id) {
        case 1: return this.props.t('qty.cup', { count: +ingredient.quantity });
        case 2: return this.props.t('qty.tsp', { count: +ingredient.quantity });
        case 4: return this.props.t('qty.tbsp', { count: +ingredient.quantity });
        case 5: return 'qty.g';
        case 6: return 'qty.kg';
        case 7: return 'qty.mL';
        case 8: return 'qty.L';

        case 3:
        default:
            return '';
    }
}

export function getIngredientQuantity(ingredient) {
    if (ingredient === null) {
        return '';
    }

    let intPart = Math.floor(ingredient.quantity);
    const decimalPart = ingredient.quantity - intPart;

    const delta = 0.02083333333;
    let fraction = decimalPart < delta ? '' : decimalPart.toFixed(2).toString();


    if (Math.abs(decimalPart - 1 / 8) <= 3 * delta) {
        fraction = '⅛';
    }
    if (Math.abs(decimalPart - 1 / 3) <= 2 * delta) {
        fraction = '⅓';
    }
    if (Math.abs(decimalPart - 1 / 4) <= 2 * delta) {
        fraction = '¼';
    }
    if (Math.abs(decimalPart - 1 / 2) <= 4 * delta) {
        fraction = '½';
    }
    if (Math.abs(decimalPart - 3 / 8) <= delta) {
        fraction = '⅜';
    }
    if (Math.abs(decimalPart - 5 / 8) <= delta) {
        fraction = '⅝';
    }
    if (Math.abs(decimalPart - 7 / 8) <= 2 * delta) {
        fraction = '⅞';
    }
    if (Math.abs(decimalPart - 2 / 3) <= 2 * delta) {
        fraction = '⅔';
    }
    if (Math.abs(decimalPart - 3 / 4) <= 4 * delta) {
        fraction = '¾';
    }
    if (decimalPart > 7 / 8 + delta) {
        intPart++;
        fraction = '';
    }

    if (intPart === 0) {
        return fraction;
    } else if (fraction === '') {
        return intPart.toString();
    } else {
        return intPart + fraction;
    }
}