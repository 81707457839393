const tr = {
    "homepage": {
        "title": "Bienvenue sur MiamMiam, en React",
        "p1a": "Cette application est un projet d'essai fait en React afin d'expérimenter avec cette librairie. Ce site est une reprise de mon application Angular officielle (",
        "p1b": "). Pour en savoir plus: ",
        "p2": "Mon objectif principal avec ce projet est de pratiquer avec un écosystème React.js complet: avec appels HTTP asynchrones, internationalisation, sécurité et plus encore...",
        "p3a": "Si par hasard vous lisez cette page, je vous souhaite la bienvenue ! Je vous invite à consulter les ",
        "p3b": " ou à vous connecter.",
        "p4": "Si vous désirez accéder au code source, allez voir mon "
    },
    "navigation": {
        "about": "À propos",
        "recipes": "Recettes",
        "public_recipes": "recettes publiques",
        "miammiam_link": "fr.miammiam.app",
        "gitlab_repo": "repo Gitlab"
    },
    "recipes": {
        "title": "Recettes"
    },
    "recipe": {
        "ingredients": "Ingrédients",
        "preparation": "Préparation",
        "prep_time": "Temps de préparation",
        "cooking_time": "Temps de cuisson",
        "cooking_temperature": "Température de cuisson",
        "servings": "Portions",
        "by": "Par"
    },
    "about": {
        "p1": " est un livre de recettes personnel et permet aussi aux utilisateurs de partager leurs recettes véganes au monde entier.",
        "p2": "Cette application est la copie en React.js du site officiel conçu avec Angular, miammiam.app. Le backend est la même API NodeJs utilisée par MiamMiam, mais ce frontend est intégralement conçu avec React.JS, ainsi que des librairies telles que Create-react-app, Axios et Milligram (framework CSS léger).",
        "p3a": "J'ai décidé de rendre ce projet open source (",
        "p3b": ") afin de recueillir des conseils et commentaires d'autre développeurs pour améliorer mes compétences Javascript. De plus, j'aime partager mes apprentissages qui pourraient bénéficier aux développeurs désirant approfondir leurs connaissances.",
        "p4": "Je suis un ingénieur en informatique, actuellement vice-président dans le rôle de tech lead et Scrum Master chez Morgan Stanley. Si vous désirez en savoir plus à mon sujet, je vous invite à visiter mon portfolio : ",
        "p5": "Passioné de technologies, je cherche constamment à apprendre et pratiquer dans de nombreux domaines, principalement axés autour du développement we : React, Javascript, Angular, Machine learning, Node, Python plein d'autres domaines.",
        "p6": "Ma soif de savoir ne s'arrête pas aux technologies : Je suis un amateur de photographie studio, un adepte de la boulangerie au levain, un youtuber, guitariste et méditateur.",
        "p7": "Mais avant tout, je suis un père de famille attentionné et je passe la majeur partie de mon temps à profiter des instants de la vie avec ma femme et mes 3 enfants.",
        "on_gitlab": "sur Gitlab"
    },
    "qty": {
        "cup": "tasse",
        "cup_plural": "tasses",
        "tsp": "cuillère à thé",
        "tsp_plural": "cuillères à thé",
        "tbsp": "cuillère à soupe",
        "tbsp_plural": "cuillères à soupe"
    }
}

export default tr;